// import React from "react"
import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider
// const onRenderBody = ({ setPostBodyComponents }) => {
//     setPostBodyComponents([<div id="modal-root" />]);
//    };
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
