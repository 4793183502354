import * as types from './types';
import { objectSize } from "../../utils/functions";

const initialState = {
    tasks: {
        '1': { title: 'Task 1', id: '1' }, '2': { title: 'Task 2', id: '2' },
        '3': { title: 'Task 3', id: '3' }, '4': { title: 'Task 4', id: '4' },
        '5': { title: 'Task 5', id: '5' }, '6': { title: 'Task 6', id: '6' }
    },
    columns: { 'col-1': { title: 'Column 1', id: 'col-1', taskId: ['1', '2', '3', '4'] }, 'col-2': { title: 'Column 2', id: 'col-2', taskId: ['5', '6'] } },
    columnOrder: ['col-1', 'col-2']
}

const TaskReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ITEMDRAG:
            const handleDrag = () => {
                const sourceCol = state.columns[action.value.source.droppableId]
                const newTaskId = [...sourceCol.taskId]
                const destinationIndex = action.value.index;
                newTaskId.splice(action.value.source.index, 1)
                newTaskId.splice(destinationIndex, 0, action.value.draggableId)
                return {
                    ...sourceCol,
                    taskId: newTaskId
                }
            }
            const singleMovementCol = handleDrag()
            return {
                ...state,
                columns: {
                    ...state.columns,
                    [singleMovementCol.id]: singleMovementCol
                }
            }
        case types.ITEM_DRAG_NEXT_COL:
            const handNextColDrag = (act = action.value) => {
                const sourceCol = state.columns[act.source.droppableId]
                const destination = state.columns[act.destination.droppableId]
                const newSourceTaskArr = [...sourceCol.taskId]
                const newDestinationTaskArr = [...destination.taskId]
                newSourceTaskArr.splice(act.source.index, 1)
                newDestinationTaskArr.splice(act.destination.index, 0, act.draggableId)
                return [
                    {
                        ...sourceCol,
                        taskId: newSourceTaskArr
                    }, {
                        ...destination,
                        taskId: newDestinationTaskArr
                    }
                ]
            }
            const multiMovement = handNextColDrag();
            return {
                ...state,
                columns: {
                    ...state.columns,
                    [multiMovement[0].id]: multiMovement[0],
                    [multiMovement[1].id]: multiMovement[1]
                }
            }
        case types.CREATE_COLUMN:
            const createColum = (act = action.value) => {
                let id = ''
                objectSize(state.columns) === 0 ? id = 1 : id = objectSize(state.columns) + 1
                const newCol = {
                    [`col-${id}`]: {
                        title: act.title,  id: `col-${id}` , taskId: []
                    }
                }
                let newColumnOrder  = [...state.columnOrder]
                newColumnOrder.push(`col-${id}`)
                return {
                    newColumnOrder,
                    newCol
                }
            }
            const newColumn = createColum()
            return {
                ...state,
                columns: Object.assign(state.columns, newColumn.newCol ),
                columnOrder: newColumn.newColumnOrder
            }
        case types.CREATE_TASK:
         const createTask = (payload = action.payload) => {
            const column = state.columns[payload.columndId]
            const newColumnArr = [...column.taskId]
            let id = ''
            objectSize(state.tasks) === 0 ? id = 1 : id = objectSize(state.tasks) + 1
            const newTask = {
                [`${id}`]: {
                    title: payload.title, description: payload.description, id: `${id}` 
                }
            }
            newColumnArr.push(`${id}`)
            return {
                newTask,
                col: {
                    ...column,
                    taskId: newColumnArr
                }
            }
         }

        const newTask =  createTask()
            return {
                ...state,
                tasks: Object.assign(state.tasks, newTask.newTask),
                columns: {
                    ...state.columns,
                    [action.payload.columndId]: newTask.col
                }
            }
        case types.DELETE_COLUMN:
            const deleteColumn = (act = action.value) => {
                const column = state.columns[act]
                const columnTasks = [...column.taskId]
                const newTasks = Object.assign(state.tasks)
                columnTasks.forEach((e) => {
                    delete newTasks[e];
                })
                const newColumns = Object.assign(state.columns)
                delete newColumns[column.title];
                const newColumnOrder = [...state.columnOrder]
                newColumnOrder.splice(action.index, 1)
                return {
                    newColumns,
                    newColumnOrder,
                    newTasks
                }    
            }
            const deleteRes = deleteColumn()
            return { 
                tasks: deleteRes.newTasks,
                columns: deleteRes.newColumns,
                columnOrder: deleteRes.newColumnOrder
            }
        default:
            return state
    }

};

export default TaskReducer;