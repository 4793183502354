// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-task-board-js": () => import("./../src/pages/task-board.js" /* webpackChunkName: "component---src-pages-task-board-js" */),
  "component---src-pages-tic-tac-toe-js": () => import("./../src/pages/tic-tac-toe.js" /* webpackChunkName: "component---src-pages-tic-tac-toe-js" */)
}

