import { createStore as reduxCreateStore, combineReducers } from "redux"
import ticTacReducer from "./reducers/ticTac";
import TaskReducer from "./reducers/taskReducer";

const rootReducer = combineReducers({
    ticTac: ticTacReducer,
    task: TaskReducer
  })
  
const createStore = () => reduxCreateStore(rootReducer)

export default createStore