import * as types from './types';

const initialState = {
    squares: Array(9).fill(null),
    xIsNext: true,
    isGameWon: false
}

const ticTacReducer = (state = initialState, action) => {
        switch (action.type) {
            case types.PLAY:
                if(state.isGameWon === true){
                    return state
                } 
                const squares = state.squares.slice();
                squares[action.value] = state.xIsNext ? 'X' : 'O';
                if(state.squares[action.value]){
                    return state
                } else{
                    return {
                        ...state,
                        squares: squares,
                        xIsNext: !state.xIsNext,
                    }
                }
            case types.RESET:
                return {
                    squares: Array(9).fill(null),
                    xIsNext: true,
                    isGameWon: false
                }
            case types.GAMESTATUS:
                return{
                    ...state,
                    isGameWon: true
                }
            default:
                return state;
        }
};

export default ticTacReducer;